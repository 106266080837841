@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;



@font-face {
    font-family: ProximaNova;
    font-weight: light;
    src: url("./../../assets/img/Copperplate Gothic Regular.otf") format("opentype");
}



body {
  background-image:url('../../assets/img/reisz gallery interior.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  color: #000;
  padding: 0;
  margin: 0;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  cursor: default;
  overflow-x: hidden;
}

#exampleModal1 { 
background-color: rgba(155, 155, 155, 0.5);
}


#rgfooter {
background-color: rgba(255, 255, 255, 1);
}



#rgfooter a{
  color: black;
  text-decoration: none;
}

#rgfooter p{
  font-family:ProximaNova;
  font-size:19px;
}

#rgfooter h4{
  font-family:ProximaNova;
}


#rgfooted {
background-color: rgba(255, 255, 255, 0);
}


#rgfooted a{
  color: white;
  text-decoration: none;
}

#rgfooted p{
  font-family:ProximaNova;
  font-size:1.4em;
  color: white;
}

#rgfooted h4{
  font-family:ProximaNova;
  color: white;
}

